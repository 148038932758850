import Vue from "vue"
import { mapState } from 'vuex'
import VueTheMask from 'vue-the-mask'
import { VMoney } from 'v-money'
import axios from 'axios'

Vue.use(VueTheMask)
// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__global__) {
	Vue.__global__ = true
	Vue.mixin({
		directives: { money: VMoney },
		data() {
			return {
				newCode: null,
			}
		},
		computed: {
			...mapState({
				languages: (state) => state.master.languages,
				userPermissions: (state) => state.settings.userPermissions,
				currentPermissionCode: (state) => state.settings.currentPermissionCode,
				systemLocale: (state) => state.settings.locale,
				systemLocales: (state) => state.master.languages,
				cmsLanguages: (state) => state.cmses.selectedCurrencyLanguages,
				translationString: (state) => state.master.translationString,
				globalDefaultCurrency: (state) => state.settings.globalSelectedCurrency,
				permissions: (state) => state.settings.userPermissions,
			}),
		},
		mounted() {
			// Add try catch, since first reload this.$cookie is undefined
			try {
				if (this.$cookie?.get('locale')) {
					this.$store.dispatch('settings/locale', this.$cookie?.get('locale'))
				}
				if (!this.$cookie?.get('permissionCode')) {
					this.$cookie.set('permissionCode', 'dashboard')
				}
			} catch (error) { }
		},
		methods: {
			// Check URL
			isURL(string) {
				let url;
				try {
					url = new URL(string);
				} catch (_) {
					if (string && !string.includes('https://') && !string.includes('http://')) {
						if (string.substring(0, 4) === 'www.') {
							return true
						}
					}
					return false;
				}
				return url.protocol === "https:" || url.protocol === "http:";
			},
			copyToClipboard(text) {
				navigator.clipboard.writeText(text)
				// notify user
				this.$toast.show(this.tl('contentCopied'))
			},
			tl(code) {
				if (Object.keys(this.translationString).length === 0) {
					return code
				}

				if (!this.translationString[code]) {
					const text = {}
					const form = {}
					for (let index = 0; index < this.systemLocales.length; index++) {
						text[this.systemLocales[index].code] = code;
					}
					form.text = { ...text };
					form.code = code;
					form.status = true;
					form.used_in = [2]

					//  ? Uncommented this line if you wanna to add dictionary automatically, [dev mode only]

					// if (!this.$store.state['language-strings'].newCodes.includes(code) && this.$cookie.get('token')) {
					// 	this.$store.dispatch(
					// 		'language-strings/addLanguageStrings',
					// 		form
					// 	)
					// }
					// this.$store.dispatch('language-strings/newCode', code);
					return code
				}

				return this.translationString[code]
			},
			formatCurrencyJS(value, removeDecimal = false) {
				if (!value) value = 0;
				const response = this.$currencyJs(
					parseFloat(value),
					{ separator: ',', symbol: '' }
				).format();
				if (removeDecimal) {
					return response.replace('.00', '')
				}
				return response
			},
			readableNumber(number) {
				if (number) {
					return new Intl.NumberFormat().format(parseFloat(number))
				}
				return 0
			},
			// Generate the excel.
			generateExcel(fileName, excelData) {
				let columnNames = ''
				const csvString = excelData.map((item) => {
					const tempColumnNames = []
					const columnValues = []
					for (const [key, value] of Object.entries(item)) {
						columnValues.push(value)
						tempColumnNames.push(key)
					}
					columnNames = tempColumnNames
					return columnValues
				})

				columnNames = columnNames.join()
				csvString.forEach(function (row) {
					columnNames += '\n' + row.join(',')
				})

				const hiddenElement = document.createElement('a')
				hiddenElement.href =
					'data:text/csv;charset=utf-8,' +
					encodeURIComponent('\uFEFF' + columnNames)
				hiddenElement.target = '_blank'
				hiddenElement.download = fileName + '.csv'
				hiddenElement.click()
			},
			async generateExcelXlsx(fileName, excelDataObject) {
				await axios.post(`/download-excel-file`, { data: excelDataObject }, { responseType: 'blob' })
					.then((response) => {
						// Create a blob object from the response data
						const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

						// Create a link element and click it to trigger the download
						const url = window.URL.createObjectURL(blob);
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `${fileName}.xlsx`); // Set desired file name here
						document.body.appendChild(link);
						link.click();

						// Clean up resources
						document.body.removeChild(link);
						window.URL.revokeObjectURL(url);
					}).catch(() => false)
			},
			momentDate(date, showTime = true) {
				if (!date) return "-";
				return this.$moment(date)
					.local()
					.format(`DD-MM-YYYY ${showTime ? 'HH:mm:ss' : ''} `)
			},
			getNameFromLocale(langObj) {
				// Return a default value if the language object is not provided or empty
				if (!langObj || Object.keys(langObj).length === 0) {
					return this.tl('noDataAvailable');
				}

				// If only one language exists in the langObj object, return its value
				if (Object.keys(langObj).length === 1)
					return langObj[Object.keys(langObj)[0]]

				//  If there are multiple languages, return its with locale or en-US
				return langObj[this.systemLocale] || langObj['en-US'] || '-'
			},
			typingSpecialNumbers(event) {
				const notAllowDash = /^[0-9.,-]/g;
				if (!notAllowDash.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
					event.preventDefault();
				}

			},
			rulesEmptyRequired(text = this.tl('isRequired')) {
				return [v => !!v || text]
			},
			isSuperAdmin() {
				return ~~this.$cookie.get('user')?.user_type === 1
			},
			disabledPlayerProfilePage() {
				if (this.isSuperAdmin()) return false
				const playerPermission = this.permissions.find(el => el.code === 'player_list')
				if (!playerPermission) return false
				if (!playerPermission.can_view) return true
				return false
			},
			goToPlayerProfile(playerId) {
				if (this.disabledPlayerProfilePage()) return null
				window.open(`/players/players/${playerId}`)
			},
			playerAge(createdAt) {
				return `${this.$moment(this.$moment().local().format('YYYY-MM-DD'))
					.local()
					.diff(
						this.$moment(createdAt)
							.local()
							.format('YYYY-MM-DD'),
						'days'
					)} ${this.tl('days')}`
			}
		}
	}) // Set up your mixin then
}
