export default {
	computed: {
		isCryptoCurrency() {
			if (this.$cookie.get('currency_type')) {
				const currencyType = parseFloat(this.$cookie.get('currency_type'))
				// 1 is fiat currency, 2 is crypto currency
				return currencyType === 2
			}
			return false;
		}
	}
}